// https://material-ui.com/guides/typescript/
import React from 'react'

import {
  createMuiTheme,
  ThemeProvider as MThemeProvider,
  Theme,
  ThemeOptions,
  responsiveFontSizes,
} from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core/'
import { ascensionTheme } from './theme'

/**
 * @interface
 * original source: ThemeProvider.d.ts
 * redeclaring this interface to allow for default Ascension Theme or a custom theme.
 */
export interface ThemeProviderProps<T = Theme> {
  children: React.ReactNode
  responsive?: boolean
  theme?: Partial<T> | T | ThemeOptions
  // theme?: Partial<T> | ((outerTheme: T) => T)
}

function createTheme({
  responsive,
  theme,
}: {
  responsive: boolean
  theme: ThemeOptions | Partial<Theme>
}): Theme {
  if (responsive && theme) return responsiveFontSizes(createMuiTheme(theme))
  return createMuiTheme(theme)
}

export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  let { children, responsive = false, theme = ascensionTheme, ...rest } = props
  const memoTheme = React.useMemo(() => createTheme({ responsive, theme }), [
    theme,
    responsive,
  ])

  return (
    <MThemeProvider theme={memoTheme} {...rest}>
      <CssBaseline />
      {children}
    </MThemeProvider>
  )
}
