import React, { CSSProperties } from 'react'
import { Alert as MAlert, AlertProps as MAlertProps } from '@material-ui/lab'
import { Box, BoxProps, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import { colors } from '../../theme'

// TODO: add generic type
export type ColorWithGeneric = 'success' | 'info' | 'warning' | 'error'

export interface AlertBoxProps
  extends Omit<Partial<MAlertProps>, 'color' | 'severity'> {
  leftBorderAccent?: boolean
  innerBoxProps?: BoxProps
  variant?: 'standard' | 'filled' | 'outlined'
  severity?: ColorWithGeneric
}

const useAscensionAlertBoxStyles = makeStyles(
  (theme: Theme) => {
    const styles: Record<string, CSSProperties> = {
      leftBorderAccent: {
        borderLeftWidth: theme.spacing(0.5),
      },
      outlined: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.common.black,
      },
      info: {
        borderColor: colors.primary['500'],
      },
      warning: {
        borderColor: colors.warning['600'],
      },
    }

    return createStyles({
      /* Styles applied to the root element. */
      root: (props: AlertBoxProps) => ({
        ...(props.leftBorderAccent && styles.leftBorderAccent),
        ...(props.variant === 'outlined' && styles.outlined),
        ...(props.severity === 'info' && styles.info),
        ...(props.severity === 'warning' && styles.warning),
      }),
    })
  },
  // The root-name of the classes generated:
  { name: 'MuiAscensionAlertBox' },
)

/**
 * Ascension Brand AlertBox - with color and content
 * @param {string} props.color - can be `success`, `warning`, `info`, `error`
 * @param {boolean} props.leftBorderAccent - can be `true` or `false`
 * @param {BoxProps} props.innerBoxProps - can be BoxProps from Material-UI
 * @param {string} props.variant - can be 'standard' | 'filled' | 'outlined'
 * @param {AlertProps} props.rest - can be rest of props in AlertProps from Material-UI
 * @param {React.ReactNode} props.action - The action to display. It renders after the message, at the end of the alert.
 * @param {string} props.closeText - Override the default label for the *close popup* icon button.
 * @param {ColorWithGeneric} props.color - The main color for the alert. Unless provided, the value is taken from the `severity` prop.
 * @param {ColorWithGeneric} props.severity - The severity of the alert. This defines the color and icon used.
 * @param {React.ReactNode} props.icon - Override the icon displayed before the children.
 * @param {string} props.role - maps the `severity` prop to a range of different icons,
 * @params {Partial<Record<Color, React.ReactNode>>} props.iconMapping - Custom icon mapping
 * @params {(event: React.SyntheticEvent) => void} props.onClose - Callback fired when the component requests to be closed.
 * @params {Partial<ClassNameMap<ClassKey>>} props.classes - Classes
 *
 * `generic` `color` can be added later
 *
 * `variant` can be `standard`, `filled`, `outlined`, with default set to `outlined`
 */

export const AlertBox = React.forwardRef<HTMLDivElement, AlertBoxProps>(
  (props, ref) => {
    const {
      children,
      variant = 'outlined',
      severity = 'success',
      innerBoxProps,
      classes,
      leftBorderAccent = false,
      ...rest
    } = props

    const styles = useAscensionAlertBoxStyles({
      variant,
      leftBorderAccent,
      severity,
    })

    const iconBySeverity = (severity: string, variant: string) => {
      if (variant === 'filled') return null

      switch (severity) {
        case 'success':
          return <CheckCircleIcon />
        case 'warning':
          return <WarningIcon style={{ color: colors.warning['600'] }} />
        case 'info':
          return <InfoIcon style={{ color: colors.primary['500'] }} />
        case 'error':
          return <ErrorIcon />
        default:
          return null
      }
    }

    return (
      <MAlert
        ref={ref}
        classes={{ ...classes, ...styles }}
        variant={variant}
        severity={severity}
        icon={iconBySeverity(severity, variant)}
        {...rest}
      >
        <Box {...innerBoxProps}>{children}</Box>
      </MAlert>
    )
  },
)
